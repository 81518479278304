<template>
  <div>

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'DirectRechargeHistory'">
      <template v-slot:right-end>
        <!--        <div class="vx-row">-->
        <!--          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat"-->
        <!--                     icon-pack="feather" icon="icon-x" size="small"-->
        <!--                     @click="clearSelectedAlpha()"></vs-button>-->
        <!--          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26"-->
        <!--                                  v-on:change="pageChanged"></vs-pagination-modified>-->
        <!--        </div>-->
      </template>
    </breadcrumb-base>

    <!--    Empty List State -->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="transactions.length === 0 && !errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--               class="mx-auto mb-4 max-w-full">-->
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
        </div>
      </div>
    </transition>

    <!--    Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>


    <transition name="fade">
      <div class="vs-row">
        <vs-table v-show="transactions.length > 0 && !errorFetching" ref="table" pagination
                  :max-items="20" search
                  :data="transactions">

          <template slot="thead">
            <vs-th >{{$t('TransactionID')}}</vs-th>
            <vs-th >{{$t('Provider')}}</vs-th>
            <vs-th >{{$t('Amount')}}</vs-th>
            <vs-th >{{$t('Mobile')}}</vs-th>
            <vs-th >{{$t('Status')}}</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td>
                <p class="font-medium truncate">{{ tr.tx_id }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium truncate">{{ tr.provider | title }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium truncate">{{ tr.amount | germanNumberFormat }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium truncate">{{ tr.mobile }}</p>
              </vs-td>
              <vs-td class style="display: flex; place-content: center;">
                <vs-chip style="margin: auto;" :color="getStatusColor(tr.status)" class="supplier-status">{{
                    getStatusText(tr.status) | title }}
                </vs-chip>
              </vs-td>

            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DirectRechargeHistory',
  data() {
    return {
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Direct Recharge History', i18n: 'DirectRechargeHistory', active: true },
      ],
    }
  },
  computed: {
    transactions() {
      return this.$store.state.directRecharge.transactions
    },
  },
  methods: {
    getStatusColor(status) {
      if (Number(status) === 1) {
        return 'success'
      } if (Number(status) === 2) {
        return 'danger'
      }
      return 'warning'
    },
    getStatusText(status) {
      if (Number(status) === 1) {
        return 'Success'
      } if (Number(status) === 2) {
        return 'Failed'
      }
      return 'Processing'
    },
  },
  created() {
    this.$vs.loading()
    this.$store.dispatch('directRecharge/fetchTransactionHistory')
      .then((data) => {
        this.$vs.loading.close()
        this.errorFetching = false
      })
      .catch((error) => {
        console.error(error)
        this.$vs.loading.close()
        this.errorFetching = true
        let msg = ''
        try {
          msg = error.response.data.message
        } catch (err) {
          msg = ''
        }
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: msg,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },
}
</script>

<style scoped>

</style>
